import { Box, Button } from "@mui/material";
import React from "react";
import { useState } from "react";
import {
  primaryColor,
  secondaryColor,
  primaryLight,
} from "../theme/setThemeColor";

const DashboardDataToggleComponent = ({ setGraphDuration }) => {
  const [isActive, setIsActive] = useState("TODAY");

  return (
    <Box
      sx={{
        display: "flex",
        "& .MuiButton-root": { mx: 0.5 },
      }}
    >
      <Button
  variant="outlined"
  sx={{
    background: isActive && isActive === "TODAY" ? '#4caf50' : '#f5f5f5', // Changed background color
    fontWeight: "bold",
    border: "1px solid #4caf50", // Updated border color
    color: isActive && isActive === "TODAY" ? "#ffffff" : '#4caf50', // Updated text color
    "&:hover": {
      border: "1px solid #388e3c", // Updated hover border color
      color: "#ffffff", // Updated hover text color
      background: '#388e3c', // Updated hover background color
    },
  }}
  onClick={() => {
    setIsActive("TODAY");
    setGraphDuration("TODAY");
  }}
>
  Today
</Button>

<Button
  variant="outlined"
  color="warning"
  sx={{
    background: isActive && isActive === "THIS" ? '#ff9800' : '#ffffff', // New background color
    fontWeight: "bold",
    border: "1px solid #ff9800", // New border color
    color: isActive && isActive === "THIS" ? "#ffffff" : '#ff9800', // New text color
    "&:hover": {
      border: "1px solid #e65100", // New hover border color
      color: "#ffffff", // New hover text color
      background: '#e65100', // New hover background color
    },
  }}
  onClick={() => {
    setGraphDuration("THIS");
    setIsActive("THIS");
  }}
>
  This
</Button>

<Button
  variant="outlined"
  sx={{
    background: isActive && isActive === "LAST" ? "#00bf78" : "#ffffff", // New active background color
    fontWeight: "bold",
    border: "1px solid #00bf78", // New border color
    color: isActive && isActive === "LAST" ? "#ffffff" : "#00bf78", // New active text color
    "&:hover": {
      border: "1px solid #00bf78", // New hover border color
      color: "#ffffff", // New hover text color
      background: "#00bf78", // New hover background color
    },
  }}
  onClick={() => {
    setGraphDuration("LAST");
    setIsActive("LAST");
  }}
>
  Last
</Button>

      {/* <ToggleButtonGroup
        orientation="horizontal"
        value={graphDuration}
        exclusive
        onChange={handleChange}
      >
        <ToggleButton
        value="TODAY"
        aria-label="list"
        className="custome-toggle cm-hover"
      >
        Today
      </ToggleButton>
        <ToggleButton
          value="THIS"
          aria-label="module"
          className="custome-toggle cm-hover"
        >
          This
        </ToggleButton>
        <ToggleButton
          value="LAST"
          aria-label="quilt"
          className="custome-toggle cm-hover"
        >
          Last
        </ToggleButton>
      </ToggleButtonGroup> */}
    </Box>
  );
};

export default DashboardDataToggleComponent;
